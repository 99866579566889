    .nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #5e32e5;
    color: #fff;
    
  }
  
  .nav-logo {
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 100px;
  }
  
  .nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    
  }
  
  .nav-link {
    margin-right: 20px;
  }
  
  .nav-link a {
    color: #fff;
    text-decoration: none;
  }
  
  .nav-toggle {
    display: none;
  }
  
  @media (max-width: 768px) {
    .nav-links {
      display: none;
      position: absolute;
      top: 60px;
      right: 20px;
      background-color: #5e32e5;
      padding: 20px;
      border-radius: 10px;
    }
  
    .nav-toggle {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: #5e32e5;
      border: none;
      padding: 10px;
      cursor: pointer;
    }
  
    .nav-toggle span {
      display: block;
      width: 20px;
      height: 2px;
      background-color: #fff;
      margin-bottom: 5px;
    }
  
    .nav-links.show {
      display: block;
    }
  
    .nav-link {
      display: block;
      margin-bottom: 10px;
    }
  
    .nav-close {
      display: block;
      text-align: right;
    }
  
    .close-btn {
      background-color: transparent;
      border: none;
      font-size: 24px;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      color: #fff;
    }
  }
  
  .nav-close {
    display: none;
  }
  
  @media (max-width: 768px) {
    .nav-close {
      display: block;
    }
  }