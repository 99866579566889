body {
    background-color: #040733; 
  }
  
  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 100px 300px;
    background-color: #040733; 
  } 
 
  .text-container {
    width: 50%;
    text-align: center;
    color: white;
  }

  .text-container p {
    font-size: 20px;
    margin-top: 20px;
    width: 100%;
    /* margin-right: 15px; */
  text-align: center;
  }
  
  .main-image {
    width: 427px;
    height: 427px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .main-container {
      flex-direction: column;
      margin: 50px 20px;
    }
    .text-container {
      width: 100%;
      margin-bottom: 20px;
    }
    .main-image {
      width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .main-container {
      margin: 20px 10px;
    }
    .main-image {
      width: 100%;
      height: auto;
    }
  }


  .button-container {
    margin-top: 20px; 
  }
  
  .call-btn, .request-btn {
    background-color: #5e32e5;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; 
    border-radius: 5px; 
  }
  
  .request-btn {
    background-color: #5e32e5;
    
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    width: 400px !important;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }


  .modal-content label {
    font-size: 16px;
    color: #333;
    display: block;
    margin-bottom: 8px;
  }
  
  .modal-content input, .modal-content textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  .modal-content textarea {
    resize: vertical;
  }
  
  .modal-content button {
    background-color: #5e32e5;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s; 
  }
  
  .modal-content button:hover {
    background-color: #3d13b1;
  }
 

  .close-button {

    margin-left: 350px;
    cursor: pointer;
  }